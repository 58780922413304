/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactHTMLParser from 'react-html-parser';
import { decodeHTML } from 'entities';
import {
    object, string, shape, bool, arrayOf, number, func,
} from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { compose } from 'recompose';
import mbpLogger from 'mbp-logger';
import Media from 'react-media';
// components
import LazyLoadWrapper from '../../../../../../GraphqlCommonComponents/LazyLoadWrapper/LazyLoadWrapper';

// helpers, redux, hooks
import findURL from '../../../../../../../../gql/queries/findURL';
import findProductPageByPartNumber from '../../../../../../../../gql/queries/findProductPageByPartNumber';
import { getFeatureFlags, getPresentationFamily, getPriceRangeLayout } from '../../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getAddressSource, getCurrencyData } from '../../../../../../../../../state/ducks/App/App-Selectors';
import determineProductUrl from '../../../../../../../../helpers/determineProductUrl';
import { shouldRenderSnipe, snipePath } from '../../../../../../GraphqlProductPage/Partials/common/commonProductPageHelper';
import customBreakpoints from '../../../../../../../../helpers/customBreakpoints';
import PriceRange from './PriceRange';
import YotpoProductReview from '../../../../../../GraphqlProductPage/Partials/YotpoProductReview';
import { getBaseCode } from '../../../../../../../../helpers/tracking/product/dataLayerProductHelpers';
import SnipeRender from '../../../../../../GraphqlProductPage/Partials/common/SnipeRender';
import { determineProductPrefixPath } from '../../../../../../../../helpers/determineInternationalizationPath';
import useImageUrlQualityTransformation from '../../../../../../../../helpers/hooks/useImageUrlQualityTransformation';
import PlantsPriceRange from './PlantsPriceRange';
import Favorite from '../../../../../../WishlistPage/Favorite';
import AltImageSlider from '../../../../AltImageSlider/AltImageSlider';

const styles = (theme) => ({
    productContentWrapperFlower: {
        '& p': {
            lineHeight: '1.3',
            fontSize: '14px',
            [theme.breakpoints.up(769)]: {
                lineHeight: '21px',
                fontSize: '18px',
                paddingBottom: '3px',
            },
        },
    },
    productContentWrapperFood: {
        '& p': {
            lineHeight: '1.3',
            fontSize: '14px',
            [theme.breakpoints.up(769)]: {
                lineHeight: '1.4',
                fontSize: '16px',
            },
        },
    },
    productName: {
        fontWeight: '400',
    },
    plantsProductName: {
        fontWeight: '400 !important',
        textAlign: 'left',
        fontSize: '16px !important',
    },
    productListingName: {
        fontWeight: 'bold',
        fontSize: '1em',
        letterSpacing: '.4px',
        fontFamily: theme?.typography?.fontFamily,
        color: theme?.palette?.colorPrimary,
        webkitFontSmoothing: 'antialiased',
    },
    productPrice: {
        fontWeight: 'bold',
    },
    plantsProductPrice: {
        fontWeight: 'bold',
        textAlign: 'right',
    },
    crossedOutRetailPrice: {
        textDecoration: 'line-through',
        color: theme.palette.colorAlert,
        fontWeight: 'normal',
    },
    productListingPrice: {
        color: theme?.palette?.colorPrimary,
        fontWeight: '700',
        fontSize: '16px',
        // padding: '0 3px',
    },
    imageCard: {
        width: '100%',
        paddingBottom: theme?.palette?.pdp?.paddingBottom || '109.565217%', // DO NOT CHANGE pre-renders height according to img aspect ratio - only change if cdn height/width change
        marginBottom: '10px',
        borderRadius: 0,
        background: '#e3e3e3',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
        position: 'relative',
    },
    plantsImageCard: {
        width: '100%',
        paddingBottom: '100%',
        marginBottom: '10px',
        borderRadius: 0,
        background: '#e3e3e3',
        boxShadow: 'none',
        position: 'relative',
    },
    noPaddingBottom: {
        paddingBottom: '0px !important',
    },
    snipe: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '160%',
            left: '-48px',
        },
    },
    plantsOverlaySnipe: {
        textTransform: 'uppercase',
        fontSize: '.8125rem',
        lineHeight: '.8125rem',
        padding: '8px 10px 5px',
        backgroundColor: 'rgb(69, 134, 6)',
        bottom: '10px',
        position: 'absolute',
        left: '1px',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px',
            lineHeight: '.875rem',
            fontSize: '.625rem',
            bottom: '8px',
        },
    },
    wineSnipe: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            left: '-72px',
            width: '145%',
            top: '0px',
        },
    },
    productImage: {
        display: 'block',
        width: 'inherit',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    productLink: {
        width: '100%',
        display: 'inline-block',
        textDecoration: 'none',
        color: '#000',
    },
    deliveryOption: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.colorPrimary,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    currentDeliveryMessageFoodBrands: {
        color: theme.palette.sympathyProductNameColor,
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'none',
        fontSize: '.8em',
        fontWeight: '700',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    futureDeliveryMessageFoodBrands: {
        color: theme.palette.availableMessageColor,
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'none',
        fontSize: '.8em',
        fontWeight: '700',
        '&:hover': {
            cursor: 'pointer',
        },
    },

    passportEligibleImage: {
        width: '50%',
        display: 'block',
        marginBottom: '3px',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            width: '80%',
        },
    },
});

const trackProductClick = ({
    name, partNumber,
    track, trackEvent,
    categoryId, categoryName,
    location,
    price,
    eventCategory = null, eventAction = null,
}) => {
    if (track) {
        const decodedName = decodeHTML(name);
        const eventDataLayer = {
            eventName: 'product_click',
            eventCategory: eventCategory || 'Collection Page',
            eventAction: eventAction || 'Product Click',
            eventLabel: decodedName,
            products: [
                {
                    name,
                    partNumber,
                    categoryId,
                    price: price?.salePrice || price?.retailPrice || '',
                    categoryName: categoryName || location?.state?.categoryName,
                },
            ],
        };
        if (typeof trackEvent !== 'undefined') {
            trackEvent(eventDataLayer);
        }
    }
};

// if starting sale price equals starting retail price AND ending sale price equals
// ending retail price then display normal retail prices else we know its a sale price */}
const getSkuPriceRange = (skuPriceRange) => {
    const { sale, retail } = skuPriceRange;
    let retailPrice;
    let salePrice;

    if (retail?.length > 1 && sale?.length > 1) {
        if (
            sale[0].value.toString() !== retail[0].value?.toString()
            || sale[1].value.toString() !== retail[1].value?.toString()
        ) {
            salePrice = sale[0].value === sale[1].value
                ? `$${sale[0].value}`
                : `$${sale[0].value} - $${sale[1].value}`;
        }
        retailPrice = retail[0].value === retail[1].value
            ? `$${retail[0].value}`
            : `$${retail[0].value} - $${retail[1].value}`;
    }
    return {
        retailPrice,
        salePrice,
    };
};
const getImagePath = (image, absoluteImagePath, imageUrl) => (
    image?.fullPath
        ? (`${image.fullPath}?height=456&width=418&sharpen=a0.5,r1,t1&quality=80&auto=webp&optimize={medium}`)
        : (`${absoluteImagePath ? image : `${imageUrl}x.jpg`}?height=456&width=418&sharpen=a0.5,r1,t1&quality=80&auto=webp&optimize={medium}`)
);

export const DesktopSimpleProduct = ({
    classes,
    name,
    partNumber,
    image,
    url,
    brand,
    skuPriceRange,
    availability,
    fromCategoryPage,
    location,
    breadCrumbArray,

    // Category
    categoryId,
    categoryName,
    categoryLegacyId,

    skipAddons,
    showMovieUpsell,
    track,
    trackData,
    trackEvent,
    isPassportEligible,
    featureFlags,
    presentationFamily,
    addressSource,
    priceRangeLayout,
    currencyData,
    absoluteImagePath,
    isSmallScreen,
}) => {
    const FIND_URL_QUERY = findURL(brand.domain, determineProductUrl(url));
    const currency = currencyData?.currencyName || 'USD';
    const FIND_PDP_BY_PART = findProductPageByPartNumber(brand.domain, partNumber, currency);
    const isFoodBrand = presentationFamily === 'food';
    const isGrowthBrand = presentationFamily === 'growthbrand';
    const isPlants = brand?.code === 'PLA';
    const [loadURL, { errorURL }] = useLazyQuery(FIND_URL_QUERY);
    const [loadPDP, { errorPDP }] = useLazyQuery(FIND_PDP_BY_PART);
    if (errorURL || errorPDP) { mbpLogger.logError({ message: `error prefetching PDP ${errorURL} || ${errorPDP}` }); }

    const imageUrl = () => {
        const imageName = image?.name?.replace('L', 'l');
        const imagePath = `${image?.path}${imageName}`;
        return image?.fullPath || imagePath;
    };
    // url of the current image in the card
    // Must have complex expression to render correctly on server (useEffect does not run)
    const currentImgSrc = useImageUrlQualityTransformation(getImagePath(image, absoluteImagePath, imageUrl()), {
        mobile: '70',
        tablet: '75',
        desktop: '80',
    });
    const [hoverImgSrc, setHoverImgSrc] = useState();
    const [hovering, setHovering] = useState(false);
    const [productHovering, setProductHovering] = useState(false);
    useEffect(() => {
        // Set the hover image
        setHoverImgSrc(`${image.path}${image?.additionalImages?.[0]}x.jpg?height=378&width=345&sharpen=a0.5,r1,t1&quality=60&auto=webp&optimize={medium}`);
    }, [image]);

    const additionalImageArray = image?.additionalImages?.length > 0;
    const imageRender = () => {
        if (!isFoodBrand && featureFlags['is-alt-image-hover-enabled'] && additionalImageArray) {
            return (
                <img
                    // This src is a temp solution until a brand specific more complete url comes from the back end.
                    src={hovering ? hoverImgSrc : currentImgSrc}
                    alt={name}
                    className={classes.productImage}
                    onMouseOver={() => { setHovering(true); }}
                    onFocus={() => { setHovering(true); }}
                    onMouseOut={() => { setHovering(false); }}
                    onBlur={() => { setHovering(false); }}
                />
            );
        }
        if (isPlants && additionalImageArray) {
            const sliderSettings = {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                lazyLoad: true,
            };
            let images = [];
            images = image?.additionalImages;
            const altImageProps = {
                images,
                settings: sliderSettings,
                hover: productHovering || isSmallScreen,
                product_name: name || 'Plants',
                path: image.path,
                mainImage: `${image?.name}x`,
            };
            return <AltImageSlider {...altImageProps} />; // eslint-disable-line react/jsx-props-no-spreading
        }
        return (
            <img
                // This src is a temp solution until a brand specific more complete url comes from the back end.
                src={currentImgSrc} // to do: do not hardcode the x and change based on screensize?
                alt={name}
                className={classes.productImage}
                loading="eager"
            />
        );
    };

    const personalizationFlag = featureFlags?.['is-personalization-enabled'] ?? false;
    const availabilityClass = availability?.deliveryMessage === 'AVAILABLE TO SHIP NOW' ? classes.currentDeliveryMessageFoodBrands : classes.futureDeliveryMessageFoodBrands;
    const imageLazyLoadingEnabled = featureFlags?.['is-simple-product-lazy-loading-enabled'];
    const determinePath = () => {
        if (brand?.code === '08F') {
            return determineProductPrefixPath(url, true);
        }
        if (brand?.code === 'CAN' || brand?.code === 'PLA') {
            return determineProductPrefixPath(url, false);
        }
        return determineProductUrl(url);
    };
    /**
     * @description - Passport elagibiility banner
     * - Hide banner on SCI channel
     * - Display banner if product is eligable and flag is enabled
     * @return {bool}
     */
    const renderPassportBanner = () => {
        if (addressSource === 'SCI') return false;
        if (isPassportEligible && featureFlags?.['is-prime-free-ship-icon-enabled'] === true) return true;
        return false;
    };

    const snipeSrc = () => {
        if (image?.snipeImagePath && !isPlants) {
            return `${image.snipeImagePath}${image.snipe}_z.gif?quality=75&auto=webp&optimize={medium}`;
        }
        return `${image.path}${image.snipe}_z.gif?quality=75&auto=webp&optimize={medium}`;
    };
    const snipeHoverImagePath = `${image.path}${image?.additionalImages?.[0]}x.jpg?height=378&width=345&sharpen=a0.5,r1,t1&quality=60&auto=webp&optimize={medium}`;
    const isWineSnipe = image?.snipe?.includes('addwine');

    const snipeImageRender = () => {
        if (!isFoodBrand && !isPlants && featureFlags['is-alt-image-hover-enabled'] && additionalImageArray) {
            return (
                <img
                    src={snipeSrc()}
                    alt={name}
                    className={classes.productImage}
                    onMouseOver={(e) => { e.currentTarget.src = snipeHoverImagePath; }}
                    onFocus={(e) => { e.currentTarget.src = snipeHoverImagePath; }}
                    onMouseOut={(e) => { e.currentTarget.src = snipeSrc(); }}
                    onBlur={(e) => { e.currentTarget.src = snipeSrc(); }}
                    loading="eager"
                />
            );
        }
        return (
            <SnipeRender
                styleProp={isWineSnipe ? classes.wineSnipe : classes.snipe}
                src={image?.snipeImagePath
                    ? `${image.snipeImagePath}${image.snipe}_z.gif`
                    : `${image.path}${image.snipe}_z.gif`}
                fallbackSrc={`${snipePath(image.path)}${image.snipe}_z.gif?quality=75&auto=webp&optimize={medium}`}
            />
        );
    };

    const imageCardHolder = brand?.code === 'PLA' ? classes.plantsImageCard : classes.imageCard;

    const ProductNameAndPriceRangeUI = () => {
        switch (brand?.code) {
            case 'PLA':
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <Typography className={classes.plantsProductName}>{ReactHTMLParser(name)}</Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography className={classes.plantsProductPrice}>
                                    {/* TO DO: NEEDS TO BE CLEANED UP AND REFACTORED  */}
                                    <Media query="(min-width: 769px)">
                                        {(matches) => <PlantsPriceRange presentationFamily={presentationFamily} priceRangeLayout={priceRangeLayout} skuPriceRange={skuPriceRange} isMobile={!matches} />}
                                    </Media>
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            default:
                return (
                    <>
                        <Grid item>
                            <Typography className={(isFoodBrand) ? classes.productListingName : classes.productName}>{ReactHTMLParser(name)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={isFoodBrand ? classes.productListingPrice : classes.productPrice}>
                                {/* TO DO: NEEDS TO BE CLEANED UP AND REFACTORED  */}
                                <Media query="(min-width: 769px)">
                                    {(matches) => <PriceRange presentationFamily={presentationFamily} priceRangeLayout={priceRangeLayout} skuPriceRange={skuPriceRange} isMobile={!matches} />}
                                </Media>
                            </Typography>
                        </Grid>
                    </>
                );
        }
    };

    return (
        <div onMouseEnter={() => { setProductHovering(true); }} onMouseLeave={() => { setProductHovering(false); }}>
            <Link
                id={name.split(' ').join('-').toLowerCase()}
                data-test="desktop-collection-product"
                title={name}
                to={{
                    pathname: `${determinePath()}`,
                    // Product Location state
                    state: {
                        prevUrl: location.pathname,
                        fromCategoryPage,
                        breadCrumbArray,
                        categoryId,
                        categoryName,
                        categoryPath: location.pathname,
                        categoryLegacyId,
                        skipAddons,
                        showMovieUpsell,
                    },
                }}
                className={classes.productLink}
                onClick={() => trackProductClick({
                    name,
                    partNumber,
                    track,
                    trackEvent,
                    categoryId,
                    categoryName,
                    location,
                    price: getSkuPriceRange(skuPriceRange),
                    eventCategory: trackData?.tracking_event_category,
                    eventAction: trackData?.tracking_event_action,
                })}
                onMouseOver={() => {
                    loadURL();
                    loadPDP();
                }}
                onFocus={() => {
                    loadURL();
                    loadPDP();
                }}
            >
                <Card className={`${imageCardHolder} ${classes.shimmer} ${isPlants && additionalImageArray ? classes.noPaddingBottom : null }`}>
                    <LazyLoadWrapper
                        config={{
                            once: true,
                            offset: 400,
                            // throttle: 200,
                        }}
                    >
                        {imageLazyLoadingEnabled && (
                            <Fade in timeout={700}>
                                {imageRender()}
                            </Fade>
                        )}
                        {!imageLazyLoadingEnabled
                            && imageRender()}
                    </LazyLoadWrapper>
                    {(shouldRenderSnipe(image?.snipe, personalizationFlag) && !isPlants) ? (
                        <LazyLoadWrapper>
                            {imageLazyLoadingEnabled && (
                                <Fade in timeout={700}>
                                    {snipeImageRender()}
                                </Fade>
                            )}
                            {!imageLazyLoadingEnabled
                                && snipeImageRender()}
                        </LazyLoadWrapper>
                    ) : null}
                    {isPlants && image?.snipe && <strong  className={classes.plantsOverlaySnipe}>{image.snipe}</strong>}
                </Card>
                {!isGrowthBrand && renderPassportBanner() ? <img className={classes.passportEligibleImage} src="https://cdn4.1800flowers.com/wcsstore/Flowers/images/2020/passport/PPEligibleIcon.svg" alt="passport_eligible" /> : null}
                <Grid container direction="column" className={(isFoodBrand) ? classes.productContentWrapperFood : classes.productContentWrapperFlower}>
                    {ProductNameAndPriceRangeUI()}
                    {(featureFlags?.['is-delivery-type-shown'] && brand?.code === '18F') && (
                        <Grid item>
                            <Typography
                                className={(isFoodBrand) ? availabilityClass : classes.deliveryOption}
                                style={(isFoodBrand) ? { fontSize: '13px' } : {}}
                                data-test={availability.deliveryMessage === 'Same-Day Local Florist Delivery' ? 'local-florist' : 'gift-box'}
                                id={`${url}`}
                                name="deliveryOption"
                            >
                                {availability.deliveryMessage}
                            </Typography>
                        </Grid>
                    )}
                    {featureFlags['is-category-yotpo-enabled'] && (
                        <>
                            <YotpoProductReview
                                partNumber={getBaseCode(partNumber)}
                                name={name}
                                productUrl={url}
                                image={image}
                            />
                        </>
                    )}
                </Grid>
            </Link>
            {isPlants && <Favorite partNumber={partNumber} hover={isSmallScreen ? true : productHovering} />}

        </div>
    );
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    addressSource: getAddressSource(state),
    presentationFamily: getPresentationFamily(state),
    priceRangeLayout: getPriceRangeLayout(state),
    currencyData: getCurrencyData(state),
});

DesktopSimpleProduct.propTypes = {
    priceRangeLayout: arrayOf(shape({
        price_layout: string,
        sale_text: string,
        viewport: string,
    })),
    classes: object.isRequired,
    name: string.isRequired,
    partNumber: string.isRequired,
    brand: object.isRequired,
    skuPriceRange: shape({
        sale: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
        retail: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
    }).isRequired,
    availability: shape({
        deliveryMessage: string.isRequired,
    }).isRequired,
    url: string.isRequired,
    image: shape({
        name: string.isRequired,
    }),
    categoryId: string,
    categoryName: string,
    categoryLegacyId: string,
    location: object.isRequired,
    fromCategoryPage: bool.isRequired,
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ),
    skipAddons: bool,
    showMovieUpsell: bool,
    track: bool,
    trackEvent: func.isRequired,
    isPassportEligible: bool,
    featureFlags: object.isRequired,
    presentationFamily: string,
    addressSource: string,
    trackData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
    }),
    currencyData: string.isRequired,
    absoluteImagePath: bool,
    isSmallScreen: bool,
};

DesktopSimpleProduct.defaultProps = {
    image: shape({
        altText: '',
    }),
    priceRangeLayout: [
        {
            price_layout: '',
            sale_text: '',
            viewport: 'Desktop',
        },
        {
            price_layout: '',
            sale_text: '',
            viewport: 'Mobile',
        },
    ],
    categoryId: '',
    categoryName: '',
    categoryLegacyId: '',
    track: true,
    skipAddons: false,
    showMovieUpsell: false,
    isPassportEligible: null,
    presentationFamily: '',
    breadCrumbArray: [],
    addressSource: '',
    trackData: {},
    absoluteImagePath: false,
    // path: '',
    isSmallScreen: false,
};

const enhance = compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps),
);

export default enhance(DesktopSimpleProduct);
