/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    object, shape, arrayOf, string,
} from 'prop-types';
import { Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import styles from './VeteransAdvantageStyles';
import MilVetComponent from './MilVetComponent';

const VeteransAdvantageBlock = ({ classes, data }) => {
    const [showVAWidget, setshowVAWidget] = useState(false);
    const pageData = data?.findContentPage?.content?.entries?.[0];
    const {
        hero: { hero_image_desktop = '', hero_image_mobile = '', copy = '' }, overview = '', article_body = '', collapsible_content = {},
    } = pageData;
    return (
        <div className={`${classes.veteransAdvantageWrapper} veteransAdvantageContainer veteransAdvantageHolder`}>
            <div />
            <Container container className="container">
                <Grid>
                    <Grid sm={12} style={{ position: 'relative' }}>
                        <img src={hero_image_desktop?.url} className="dHero" alt="Veterans Advantage" />
                        <img src={hero_image_mobile?.url} className="mHero" alt="Veterans Advantage" />
                        <div className="affiliate-div">
                            <ReactMarkdown escapeHtml={false} source={copy} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className="container box">
                <Grid className="mobileDiv">
                    <Grid sm={12} direction="column" item>
                        <ReactMarkdown escapeHtml={false} source={collapsible_content?.copy} className="mobileDescription" />
                    </Grid>
                </Grid>
                <Grid className="row">
                    <Grid lg={6} className="boxWrapper borderCenter padding">
                        <div className="discountBox">
                            <ReactMarkdown escapeHtml={false} source={overview} />
                            <Link onClick={(e) => { e.preventDefault(); setshowVAWidget(true); }} to="veterans-advantage" className="">Shop Now</Link>
                        </div>
                    </Grid>
                    <Grid lg={6} className="boxWrapper padding">
                        <div className="discountBox">
                            <ReactMarkdown escapeHtml={false} source={article_body} />
                            <Link onClick={(e) => { e.preventDefault(); setshowVAWidget(true); }} to="/veterans-advantage" className="" aria-label="Shop Now">Shop Now</Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {showVAWidget && <div className="bottomSpace"><MilVetComponent showVAWidget={showVAWidget} setshowVAWidget={setshowVAWidget} /></div>}
        </div>
    );
};

VeteransAdvantageBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        backgroud_image: string.isRequired,
        signup: string.isRequired,
        disclaimer: string.isRequired,
        header: shape({
            title: string.isRequired,
            description: string.isRequired,
            image_url: string.isRequired,
        }),
        middle_offers: arrayOf(
            shape({
                link_url: string.isRequired,
                offer_text_top: string.isRequired,
                big_text: string.isRequired,
                offer_text_bottom: string.isRequired,
                shop_now_image: string.isRequired,
            }),
        ),
    }).isRequired,
};

export default withStyles(styles)(VeteransAdvantageBlock);
