/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';
import VAConfig from './config';

const cookies = new Cookies();

// Set Cookies for transactionId
export const setMilVetTransactionId = (transactionId) => {
    cookies.set('transactionId', transactionId, { expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000) }); // transactionId cookie will expire in 1 day (24 hrs) from now on.
};

export const getMilVetTransactionId = () => (cookies.get('transactionId')) || null;

// Set Deal Id for application of promocode
export const setMilVetDealId = (dealId) => {
    cookies.set('dealId', dealId, { expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000) });  // dealId cookie will expire in 1 day (24 hrs) from now on.
};

export const getMilVetDealId = () => (cookies.get('dealId')) || null;

export const getVAPromotionData = [{ discount_code: VAConfig.TWENTY_PERCENT_PROMO_CODE, dealId: VAConfig.TWENTY_PERCENT_DEAL_ID }, { discount_code: VAConfig.TEN_PERCENT_PROMO_CODE, dealId: VAConfig.TEN_PERCENT_DEAL_ID }];

export const eyebrowVAHeader = (selectedDealId) => {
    const dealPlaceholderData = {
        eyebrow_background_color: '#000',
        eyebrow_text: `Veterans Advantage Discount applied at checkout: ${Object.values(getVAPromotionData.find((item) => item['dealId'] === selectedDealId)).length && getVAPromotionData.find((item) => item['dealId'] === selectedDealId).discount_code}`,
        eyebrow_color: '#fff',
    };
    return dealPlaceholderData;
};
